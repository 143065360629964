import React, {useState, useEffect, useRef} from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import VideoPlayer from "react-background-video-player";
import "./OlafurArnalds.css";
import BackButton from './Components/BackButton';

function OlafurArnalds({url, handlePlayAudio, handlePauseAudio}) {

    const [mute, setMute] = useState(true);
    let videoLink = 'https://www.dropbox.com/scl/fi/hox57ilm6t64eogo50ph8/Olafur-Arnalds_island.mp4?rlkey=ny4t59qwgju6ie9ccvb4thh6b&dl=1';

    const [playingVideo, setPlayingVideo] = useState(false);

    useEffect(() => {
        console.log('Mute is now: ', mute);
    }, [mute]);

    const handleClick = event => {
        setMute(false);
    };
    const showIsland = event => {
        url = 'https://www.dropbox.com/scl/fi/hox57ilm6t64eogo50ph8/Olafur-Arnalds_island.mp4?rlkey=ny4t59qwgju6ie9ccvb4thh6b&dl=1'
    }
    const showManchester = event => {
        url = 'https://www.dropbox.com/scl/fi/dk17fffr0nqup58gep3z3/Olafur-Arnalds_manchester.mp4?rlkey=ihi87qj17l16swlzs1hk0hx13&dl=1'
    }

    const playVideo = event => {
        // You can use the play method as normal on your video ref
        // debugger;
        setPlayingVideo(true)
        //this.refs.vidRef.getInternalPlayer().playVideo()
      }

    return (
        <>
            <Container className="main-container" fluid style={{ width: "100%", height: "100%" }}>
            <div style={{ width: "100%", height: "100%" }}>
                <VideoPlayer
                    className="video"
                    src={videoLink}
                    autoPlay={true}
                    muted={mute}
                />
            </div>
            <BackButton handlePauseAudio={handlePauseAudio}  handlePlayAudio={handlePlayAudio}></BackButton>
            <div className="buttons-right">
                <button href="#" onClick={handleClick}>Unmute</button>
                <button href="#" onClick={() => setMute(true)}>Mute</button>
            </div>
            <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
            <Row style={{ height: "100%", width: "100%" }}>
                <Col sm={'6'} className='island' style={{marginBottom: 20}}>
                    <span style={{color:'#fff'}}>REYKJAVIK</span>
                </Col>
                <Col sm={'6'} className='manchester' style={{marginBottom: 20}}>
                    <span>MANCHESTER</span>
                </Col>
            </Row>
            </div>
            </Container>
        </>
    );
}

export default OlafurArnalds;