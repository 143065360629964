import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "./Splash.css";
import LogoImage from "./Images/PressPausePlayLogo.jpg";

function Splash({handlePlayAudio}) {
    return (
        <>
            <Container className="main-container splash-container" fluid style={{ width: "100%" }}>
            
            <div style={{ height: "100vh" }} className="d-flex flex-column align-items-center justify-content-center">
                <Row>
                    <Col sm={'auto'}>
                        <img className="splash-logo" width={200} src={LogoImage} alt="Logo" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={'auto'}>
                        <Link onClick={handlePlayAudio} className="button button-enter display-4" to="/Home">Enter</Link>
                    </Col>
                </Row>
            </div>
            </Container>
        </>
    );
}

export default Splash;