import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./Stories.css";
import BackButton from './Components/BackButton';
import BackgroundVideo from './Components/BackgroundVideo';
import { Link } from 'react-router-dom';

function Stories({handlePlayAudio, handlePauseAudio}) {
    var audio = new Audio("https://www.dropbox.com/scl/fi/7cy6hmpec2coub4mwco9a/click.mp3?rlkey=g7ruucwulmv0z3uq3yyxvmb6e&dl=1");

    const handleClick = event => {
        handlePauseAudio();
        audio.play();
    };
    return (
        <>
            <Container className="main-container stories-container" fluid style={{ width: "100%" }}>
            <BackButton handlePauseAudio={handlePauseAudio}  handlePlayAudio={handlePlayAudio}></BackButton>
            <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
            <Row style={{ height: "100%", width: "100%" }} class="align-items-center">
                <Col sm={'6'}>
                    <Link onClick={handleClick} className="video-link" to="/story/olafur-arnalds">
                        <BackgroundVideo video="https://www.dropbox.com/scl/fi/60zkd5qr7ffmlcibgmbdu/Olafur-Arnalds_Clip.mp4?rlkey=plxccvr2cop2w0kpybv5rahxv&dl=1"></BackgroundVideo>
                        <span className='label'>Ólafur Arnalds</span>
                    </Link>
                </Col>
            </Row>
            </div>
            </Container>
        </>
    )
}

export default Stories