import React, {useState, useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Routes, Route, useParams } from 'react-router-dom';
import VideoPlayer from "react-background-video-player";
import "./Interview.css";
import BackButton from './Components/BackButton';

function Interview({handlePlayAudio, handlePauseAudio}) {
    let { interviewSlug } = useParams();

    const [mute, setMute] = useState(true);

    useEffect(() => {
        console.log('Mute is now: ', mute);
    }, [mute]);

    const handleClick = event => {
        setMute(false);
      };

    const movies = new Map();
    movies.set('moby', 'https://www.dropbox.com/scl/fi/pnqaw7x1ykebu46juliaq/Moby.mp4?rlkey=9x1i50qrswh6j49un6yqeackk&dl=1');
    movies.set('hank-shocklee', 'https://www.dropbox.com/scl/fi/z5gokzs7w5rxvwg8ug2pw/Hank-Shocklee.mp4?rlkey=9npc8p770wz1qk22dzgpv99zt&dl=1');
    movies.set('brenda-walker', 'https://www.dropbox.com/scl/fi/mkq64y0mzsw4e2cm88g2u/Brenda-Walker.mp4?rlkey=tj5ha9v6wskbly3c1lrxlrsnq&dl=1');
    movies.set('lena-dunham', 'https://www.dropbox.com/scl/fi/kaipi5srzoqo5tw1z978k/Lena-Dunham.mp4?rlkey=a7n6a3lqsm1uj9ee7o1skl8k4&dl=1');
    movies.set('andrew-keen', 'https://www.dropbox.com/scl/fi/tzbai4blhqqhgpo856q7y/Andrew-Keen.mp4?rlkey=hbbbxu7ikgzpjj6flobmzkb9q&dl=1');
    movies.get(interviewSlug);
    let movie = movies.get(interviewSlug);

    return (
        <>
            <Container className="main-container interview-container" fluid style={{ width: "100%" }}>
            <div style={{ width: "100%", height: "100%" }}>
                <VideoPlayer
                    className="video"
                    src={movie}
                    autoPlay={true}
                    muted={false}
                />
            </div>
            <BackButton handlePauseAudio={handlePauseAudio}  handlePlayAudio={handlePlayAudio}></BackButton>
            <div className="buttons-right">
                <button href="#" onClick={handleClick}>Unmute</button>
                <button href="#" onClick={() => setMute(true)}>Mute</button>
            </div>
            </Container>
        </>
    );
}

export default Interview;