import React, {useState} from 'react'
import VideoPlayer from "react-background-video-player";
import "./BackgroundVideo.css";

class BackgroundVideo extends React.Component {
    render() {
        return (
            <>
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <VideoPlayer
                className="video"
                src={this.props.video}
                autoPlay={true}
                muted={true}
            />
            </div>
            </>
        );
    }
}

export default BackgroundVideo;