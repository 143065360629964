import React from 'react'
import { useNavigate } from "react-router-dom";
import "./BackButton.css";

function BackButton({handlePlayAudio, handlePauseAudio}) {
    const navigate = useNavigate();

    const handleClick = event => {
        handlePlayAudio();
        navigate(-1)
    };

    return (
        <>
            <div className="button-back">
                <button href="#" onClick={() => handleClick()}>Go back</button>
            </div>
        </>
    );
}

export default BackButton;