import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import VideoPlayer from "react-background-video-player";
import { Link } from "react-router-dom";
import "./Home.css";
import BackButton from './Components/BackButton';

function Home({handlePlayAudio, handlePauseAudio}) {
    return (
        <>
            <Container className="main-container home-container" fluid style={{ width: "100%" }}>
            <div style={{ width: "100%", height: "100%" }}>
                <VideoPlayer
                    className="video"
                    src="https://www.dropbox.com/scl/fi/356t93emezx6jgu6kt9mk/Prelude_2.mp4?rlkey=6c6an1agfitj5j1ib9hq5y3pm&dl=1"
                    autoPlay={true}
                    muted={true}
                />
            </div>
            <BackButton handlePauseAudio={handlePauseAudio}  handlePlayAudio={handlePlayAudio}></BackButton>
            <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
            <Row className="gap-5">
                    
                <Col sm={'auto'}>
                    <Link className="display-5 button" to="/interviews">Entrevistas</Link>
                </Col>
                <Col sm={'auto'}>
                    <Link className="display-5 button" to="/stories">Histórias</Link>
                </Col>
                </Row>
            </div>
            </Container>
        </>
    );
}

export default Home;