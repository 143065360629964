import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Splash from "./Splash"
import Interviews from "./Interviews"
import Stories from "./Stories"
import Home from "./Home"
import Interview from "./Interview"
import OlafurArnalds from "./OlafurArnalds"
import Story from "./Story"

function App() {
  var audio = new Audio("https://www.dropbox.com/scl/fi/cjttn9eu9dl1cjtdeyy0w/2-Ert-J-r-in.mp3?rlkey=welc7vt01n05yrvi0edgjo7d2&dl=1");
  function playAudio() {
    audio.play();
  }
  const pauseAudio = () => {
    audio.pause();
  };
  
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={ <Splash handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio} /> } />
          <Route path="home" element={ <Home handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio}/> } />
          <Route path="interviews" element={ <Interviews handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio} /> } />
          <Route path="stories" element={ <Stories handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio} /> } />
          <Route path="interview/:interviewSlug" element={ <Interview handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio}/> } />
          <Route path="Story/:storySlug" element={ <Story handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio} /> } />
          <Route path="Story/olafur-arnalds" element={ <OlafurArnalds handlePauseAudio={pauseAudio}  handlePlayAudio={playAudio} /> } />
        </Routes>
     </div>
      {/*
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    */}
    </>
  );
}

export default App;
