import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./Interviews.css";
import BackButton from './Components/BackButton';
import BackgroundVideo from './Components/BackgroundVideo';
import { Link } from 'react-router-dom';

function Interviews({handlePlayAudio, handlePauseAudio}) {
    var audio = new Audio("https://www.dropbox.com/scl/fi/7cy6hmpec2coub4mwco9a/click.mp3?rlkey=g7ruucwulmv0z3uq3yyxvmb6e&dl=1");

    const handleClick = event => {
        handlePauseAudio();
        audio.play();
    };
    return (
        <>
            <Container className="main-container interviews-container" fluid style={{ width: "100%" }}>
            <BackButton handlePauseAudio={handlePauseAudio}  handlePlayAudio={handlePlayAudio}></BackButton>
            <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
            <Row style={{ height: "100%", width: "100%" }}>
                <Col sm={'3'}>
                    <Link onClick={handleClick} className="video-link" to="/interview/moby">
                        <BackgroundVideo video="https://www.dropbox.com/scl/fi/puydi7xo99rv7e82km8z6/Moby_Clip.mp4?rlkey=0sf1ieo8or4g39abg2y1tlcmq&dl=1"></BackgroundVideo>
                        <span className='label'>Moby</span>
                    </Link>
                </Col>
                <Col sm={'3'}>
                    <Link onClick={handleClick} className="video-link" to="/interview/hank-shocklee" onClick={handleClick}>
                        <BackgroundVideo video="https://www.dropbox.com/scl/fi/jhnbkpk4jjyaxk3bcar5v/Hank-Shocklee_Clip.mp4?rlkey=f9ako31b9igehjh44he8xmyrh&dl=1"></BackgroundVideo>
                        <span className='label'>Hank Shocklee</span>
                    </Link>
                </Col>
                <Col sm={'3'}>
                    <Link onClick={handleClick} className="video-link" to="/interview/brenda-walker" onClick={handleClick}>
                        <BackgroundVideo video="https://www.dropbox.com/scl/fi/y6qm96lkonq6rp4rlyps7/Brenda-Walker_Clip.mp4?rlkey=mwyy3jduxqqo6zkhkya5w6qd1&dl=1"></BackgroundVideo>
                        <span className='label'>Brenda Walker</span>
                    </Link>
                </Col>
                <Col sm={'3'}>
                    <Link onClick={handleClick} className="video-link" to="/interview/lena-dunham" onClick={handleClick}>
                        <BackgroundVideo video="https://www.dropbox.com/scl/fi/c1kcahuo5pqhq8ahowngh/Lena-Dunham_Clip.mp4?rlkey=xbb56qouf5qzkaal4787id5wf&dl=1"></BackgroundVideo>
                        <span className='label'>Lena Dunham</span>
                    </Link>
                </Col>
                <Col sm={'3'}>
                    <Link onClick={handleClick} className="video-link" to="/interview/andrew-keen" onClick={handleClick}>
                        <BackgroundVideo video="https://www.dropbox.com/scl/fi/hgibuc4sj8n3lnsq1t2qe/Andrew-Keen_Clip.mp4?rlkey=ibvyqd5bxxph2e1uc0ujt58b5&dl=1"></BackgroundVideo>
                        <span className='label'>Lena Dunham</span>
                    </Link>
                </Col>
                <Col sm={'3'}>
                
                </Col>
                <Col sm={'3'}>
                
                </Col>
                <Col sm={'3'}>
                
                </Col>
                <Col sm={'3'}>
                
                </Col>
                <Col sm={'3'}>
                
                </Col>
                <Col sm={'3'}>
                
                </Col>
                <Col sm={'3'}>
                
                </Col>
            </Row>
            </div>
            </Container>
        </>
    )
}

export default Interviews